<template>
    <div class="flex flex-col gap-[12px] p-[20px]">
        <button class="flex flex-start gap-[10px] items-center group" @click="onShareLink()">
            <img width="24" height="24" src="@assets/images/icons/share-07.svg" alt="" class="filter-black">
            <span class="text-[#0A0D14] font-medium text-base">Share wishlist</span>
            <img width="24" height="24" src="@assets/images/icons/chevron-right.svg" alt="" class="filter-black ml-auto transition-transform group-hover:translate-x-[6px]">
        </button>
        <hr>
        <button class="flex flex-start gap-[10px] items-center group" @click="onRename()">
            <img width="24" height="24" src="@assets/images/icons/edit-02.svg" alt="" class="filter-black">
            <span class="text-[#0A0D14] font-medium text-base">Rename</span>
            <img width="24" height="24" src="@assets/images/icons/chevron-right.svg" alt="" class="filter-black ml-auto transition-transform group-hover:translate-x-[6px]">
        </button>
        <hr>
        <button class="flex flex-start gap-[10px] items-center group" @click="onDelete()">
            <img width="24" height="24" src="@assets/images/icons/trash-03.svg" alt="" class="filter-black">
            <span class="text-[#0A0D14] font-medium text-base">Delete</span>
            <img width="24" height="24" src="@assets/images/icons/chevron-right.svg" alt="" class="filter-black ml-auto transition-transform group-hover:translate-x-[6px]">
        </button>
    </div>
</template>
<script>
import useListStore from '@/stores/List';

export default {
    setup: () => ({
        store: useListStore()
    }),
    props: {
        interface: {
            type: Object,
            required: true
        },
        params: {
            type: Object,
            default: null
        }
    },
    mounted() {
        this.interface.modal.setHeader('Settings', 'settings-04')
    },
    methods: {
        async onShareLink() {
            const url = `${location.origin}/profile/lists/${this.store.currentList.id}`;
            await navigator.clipboard.writeText(url);

            this.interface.notify("List link copied!");
            this.interface.modal.close();
        },
        onRename() {
            this.interface.modal.setView('ModalListRename');
        },
        onDelete() {
            this.interface.modal.setView('ModalListDelete');
        },
    }
}
</script>